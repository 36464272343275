<template>
  <SharedEditDialog>
    <template #title>
      <p class="title">{{ messages.editMyLanguages }}</p>
    </template>
    <template #content>
      <Loading v-if="loading" class="loader"></Loading>
      <div v-else>
        <div class="d-flex flex-row flex-wrap pt-6" style="gap: 8px">
          <BaseCheckboxReg
            v-for="(lang, i) in languagesOptions"
            :key="`language-${lang.id}`"
            v-model="languagesOptions[i].value"
            :label="lang.label"
          />
        </div>
      </div>
    </template>
    <template #actions="{ closeDialog }">
      <BaseButton
        :disabled="loading"
        outlined
        :clear="true"
        color="grayscale-darken3"
        class="mr-4 cancel-button"
        @click="closeDialog"
        >{{ messages.void }}</BaseButton
      >
      <BaseButton
        :disabled="!isFormValid || loading"
        class="confirm-button"
        @click="closeDialog(submitChangeProfileDetails)"
      >
        {{ messages.confirmChanges }}
      </BaseButton>
    </template>
  </SharedEditDialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import validateInputDefer from "@/mixins/validateInputDefer"
import spinnerMixin from "@/mixins/spinnerMixin"
import notyMixin from "@/mixins/notyMixin"
import parseDate from "@/mixins/parseDate"
import pick from "lodash.pick"
import translateLanguage from "@/mixins/translateLanguage"

export default {
  mixins: [validateInputDefer, spinnerMixin, notyMixin, parseDate, translateLanguage],
  data: () => ({
    modal: false,
    languagesOptions: [],
  }),
  computed: {
    ...mapGetters("layout", {
      dialogData: "getDialogConfig",
    }),
    messages() {
      return {
        editMyLanguages: this.$t("message.editMyLanguages"),
        cancel: this.$t("message.cancel"),
        confirm: this.$t("message.confirm"),
        void: this.$t("message.void"),
        confirmChanges: this.$t("message.confirmChanges"),
        approve: this.$t("message.approve"),
        close: this.$t("message.close"),
      }
    },
    isFormValid() {
      return !this.languagesOptions.every((lang) => lang.value)
    },
  },
  created() {
    this.languagesOptions = this.dialogData.data.languages_learned.map((lang) => ({
      id: lang.language_id,
      value: true,
      label: this.translateLanguageById({ id: lang.language_id }),
    }))
  },
  methods: {
    ...mapActions("profile", {
      updateLanguages: "updateLanguages",
    }),
    async submitChangeProfileDetails() {
      if (this.isFormValid) {
        const data = {
          ...pick(this.dialogData.data, [
            "first_name",
            "last_name",
            "phone_number",
            "date_of_birth",
          ]),
          language_ids: this.languagesOptions.filter((lang) => lang.value).map((lang) => lang.id),
        }
        try {
          this.isLoading()
          await this.updateLanguages(data)
          this.simpleSuccess({ text: this.$t("message.success.editProfile") })
        } catch (_) {
          this.simpleError({ text: this.$t("message.error.updateData") })
          return Promise.reject("failed to update profile data")
        } finally {
          this.isLoaded()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--v-grayscale-darken3);
}

.cancel-button {
  width: 81px !important;
  height: 49px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 0;
  border: 1px solid var(--v-grayscale-lighten4);
  color: var(--v-grayscale-darken3) !important;
}

.input-container {
  position: relative;

  &__label {
    font-weight: 500;
    font-size: 1rem;
    color: var(--v-primary-darken3);
  }

  &__input {
    width: 100%;
    height: 48px;
    padding: 0.75em 0.5rem;
    background: var(--v-primary-lighten2);
    border: 1px solid var(--v-grayscale-lighten4);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    &--error {
      color: var(--v-error-darken1);
      outline: 2px solid var(--v-error-darken1);
    }

    &:focus {
      outline: 3px solid var(--v-primary-base) !important;
    }
  }

  &__date-picker {
    background: var(--v-primary-lighten2);
    border: 1px solid var(--v-grayscale-lighten4);
    border-radius: 8px;
    height: 48px;
    width: 182px;
    padding: 0 1rem !important;
    display: flex;
    align-items: center;
    font-size: 1rem !important;

    &__button {
      padding: 0 1rem !important;
    }

    ::v-deep input {
      font-size: 1rem !important;
    }
  }
  &__error-message {
    position: absolute;
    bottom: -25px;
    font-size: 0.875rem;
    color: var(--v-error-darken1);
  }
}

.confirm-button {
  min-width: 167px;
}

.v-btn::before {
  display: none !important;
}

::v-deep .v-date-picker-title {
  display: flex;
  flex-direction: row-reverse;
}

::v-deep .v-picker__title__btn {
  color: white !important;
  font-size: 24px;
}

.loader {
  margin: 24px 0 0;
}
</style>
