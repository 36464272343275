<template>
  <v-container class="harmonogram card mb-3" d-flex flex-column>
    <div v-if="showTitle" class="harmonogram__title d-flex flex-row align-start mb-4">
      {{ scheduleTitle }}
    </div>
    <Loading v-if="loading" />
    <template v-else>
      <WeekSwitch
        class="harmonogram__week d-flex flex-row align-center mb-4 mt-2"
        :range="getWeekRange()"
        :is-first-week="isFirstWeek"
        :is-last-week="isLastWeek"
        @prev="getPrevWeek"
        @next="getNextWeek"
      />
      <ScheduleSelected :selected="selected" />
      <ScheduleDay
        ref="schedule"
        :class="`harmonogram__schedule schedule ${scheduleWeekClass}`"
        :week="week"
        :week-days-style="scheduleWidth"
      >
        <EmptyWeek v-if="!weekHasSlots" />
        <template v-for="(day, index) in week" v-else>
          <div v-if="displayDay(day)" :key="index" class="schedule__time time">
            <div
              v-for="(slot, idx) in getCurrentDaySlots(day)"
              :key="`${index}-${slot.time}-${idx}`"
            >
              <DaySlot
                :limit-reached="isLimitReached"
                class="schedule__slot"
                :day-slot="slot"
                @pick="pickSlot(day, slot)"
              />
            </div>
          </div>
          <div v-else :key="`empty-${index}`" class="schedule__time time">
            <span class="shedule__slot--empty">–</span>
          </div>
        </template>
      </ScheduleDay>
    </template>
    <BaseButton
      v-if="thereAreSelectedLessons && !customSchedule"
      class="submit-button"
      @click="showConfirmLesson"
    >
      <span>{{ $t("message.scheduleLesson") }}</span>
    </BaseButton>
  </v-container>
</template>

<script>
import { get } from "vuex-pathify"
import { format, parse } from "fecha"
import { sync } from "vuex-pathify"
import { mapActions, mapState, mapMutations, mapGetters } from "vuex"
import Loading from "../UI/Loading.vue"
import EmptyWeek from "./ScheduleEmptyWeek.vue"
import DaySlot from "./ScheduleDaySlot.vue"
import ScheduleDay from "./ScheduleDay.vue"
import WeekSwitch from "./ScheduleWeekSwitch.vue"
import ScheduleSelected from "./ScheduleSelected.vue"
import spinnerMixin from "@/mixins/spinnerMixin"
import alertMixin from "@/mixins/notyMixin"
import weekSwitcherMixin from "@/mixins/weekSwitcherMixin"

const DATE_FORMAT = "YYYY-MM-DD"
const getWeekDay = (date) => format(new Date(date), DATE_FORMAT)

export default {
  name: "WorkSchedule",
  components: { Loading, EmptyWeek, DaySlot, ScheduleDay, WeekSwitch, ScheduleSelected },
  mixins: [alertMixin, spinnerMixin, weekSwitcherMixin],
  props: {
    teacherId: {
      type: String,
      required: true,
    },
    language: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        level: "",
        name: "",
      }),
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    customSchedule: {
      type: Boolean,
      required: false,
    },
    initWeek: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      scheduleWidth: "width: 500px",
    }
  },

  computed: {
    dateFrom: sync("lessons/dateFrom"),
    dateTo: sync("lessons/dateTo"),
    lessonsLimit: sync("lessons/limit"),
    ...mapState("lessons", ["selected", "harmonogram", "scheduledLessonsCount"]),
    ...mapGetters("profile", {
      userActiveUntil: "getUserActiveUntil",
    }),
    isLimitReached: get("lessons/isLimitReached"),
    dialog: get("layout/getDialogConfig"),

    scheduleTitle() {
      return this.$t("schedule.title")
    },
    limitReached() {
      return this.$t("schedule.limitReached", { limit: this.lessonsLimit })
    },

    weekHasSlots() {
      const first = +new Date(this.firstDayOfWeek).setHours(23, 59, 59, 999)
      const last = +new Date(this.lastDayOfWeek).setHours(23, 59, 59, 999)
      return this.harmonogram
        ? Object.keys(this.harmonogram).some((day) => {
            const date = parse(day, "YY-MM-DD").setHours(23, 59, 59, 999)
            return date >= first && date <= last
          })
        : false
    },
    scheduleWeekClass() {
      if (this.isFirstWeek) {
        return "schedule--first-week"
      } else if (this.isLastWeek) {
        return "schedule--last-week"
      } else {
        return ""
      }
    },
    thereAreSelectedLessons() {
      return Object.keys(this.selected).length > 0
    },
  },
  watch: {
    teacherId(newId) {
      this.setTeacherId(newId)
      this.setSchedule(newId)
    },
    week: "fetchSchedule",
  },
  created() {
    this.isLoading()
    if (this.$props.initWeek.length) this.week = this.$props.initWeek
    this.setTeacherId(this.teacherId)
    window.addEventListener("resize", this.onResize)
  },
  destroyed() {
    this.setSelected({})
    window.removeEventListener("resize", this.onResize)
  },
  methods: {
    ...mapActions({
      setDialog: "layout/setDialog",
      selectLesson: "lessons/selectLesson",
      setSchedule: "lessons/setSchedule",
    }),
    ...mapMutations("lessons", {
      setSelected: "SET_SELECTED",
      setTeacherId: "SET_TEACHER_ID",
    }),

    async fetchSchedule() {
      const activeUntil = parse(this.userActiveUntil, "YYYY-MM-DD")
      const lastDayOfWeek = this.week.at(-1)
      this.dateFrom = getWeekDay(this.week[0] <= new Date() ? new Date() : this.week[0])
      this.dateTo = activeUntil <= lastDayOfWeek ? activeUntil : getWeekDay(lastDayOfWeek)
      try {
        this.setSchedule(this.teacherId)
      } catch (err) {
        console.error(err)
      } finally {
        this.isLoaded()
      }
    },

    checkPastDays(slotDate) {
      const todayDay = new Date().getDate()
      const todayMonth = new Date().getMonth()
      if (todayMonth === slotDate.getMonth()) {
        return todayDay <= slotDate.getDate() ? true : false
      } else return true
    },

    checkPastSlot(slot, day) {
      const slotDay = day.setHours(0, 0, 0, 0)
      const todayDay = new Date().setHours(0, 0, 0, 0)
      if (slotDay === todayDay) {
        const timeSplitted = slot.time.split(":")
        const slotTime = new Date().setHours(timeSplitted[0], timeSplitted[1], 0)
        return new Date() < new Date(slotTime) ? true : false
      }
      return true
    },

    getDateFormat(date) {
      return new Date(date).toLocaleDateString(this.locale, {
        weekday: "short",
        day: "numeric",
      })
    },

    getCurrentDaySlots(day) {
      const dayObj = Object.keys(this.harmonogram).find((key) => {
        const date = new Date(key)
        const cond = date.getDate() === day.getDate()

        return cond
      })
      if (!dayObj) return {}
      else {
        const harmonogram = this.harmonogram[dayObj].filter((slot) => {
          return this.checkPastSlot(slot, day)
        })
        return harmonogram
      }
    },
    pickSlot(day, slot) {
      if (!slot.available || slot.scheduled || slot.missed) return
      const lesson = { id: slot.id, day, time: slot.time, forceDelete: this.isLimitReached }
      this.selectLesson(lesson)
    },
    getSlotText(date, index, time) {
      const day = new Date(date).toLocaleDateString(this.locale, {
        weekday: "short",
        day: "numeric",
      })
      return `${index} ${day} ${time}`
    },
    async showConfirmLesson() {
      await this.setDialog({
        active: true,
        dialogType: "ScheduleConfirmLessons",
        data: {
          selected: this.selected,
        },
      })
    },
    onResize() {
      this.scheduleWidth = `width: ${this.$refs.schedule?.clientWidth}px;`
    },
    displayDay(day) {
      return Object.keys(this.getCurrentDaySlots(day)).length && this.checkPastDays(day)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/rwd_breakpoints.scss";

.harmonogram {
  &__title {
    font-size: 1.25rem;
  }
  &__week {
    font-size: 1rem;
  }
  &__schedule {
    min-height: 25rem;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $bp-mt) {
      overflow: auto;
      align-items: flex-start;
    }
  }
}
::v-deep {
  .schedule {
    &__row {
      margin-top: 10px;
      justify-content: flex-start !important;
    }
    &__slot {
      display: flex;
      flex-direction: column;
      align-items: center;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__date {
      font-size: 0.875rem;
      text-align: center;
      text-transform: capitalize;
    }
    &__date,
    &__time {
      min-width: 78px;
      width: 100%;
      text-align: center;
    }
  }
  .time {
    &__slot {
      margin: 0 0.5rem;
      padding: 0.25rem 0.75rem;
    }
  }
}

.submit-button {
  @media (max-width: $bp-m) {
    margin-top: 20px;
  }
}

::v-deep .v-btn__content {
  span {
    color: white !important;
  }
}
</style>
