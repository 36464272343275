<template>
  <SharedDialog class="dialog">
    <template #title>
      <h2 class="dialog__title mb-3">{{ title }}</h2>
      <p class="dialog__subtitle mb-5">{{ subtitle }}</p>
    </template>
    <template #content>
      <Loading v-if="loading" class="my-16"></Loading>
      <template v-else>
        <div>
          <div v-for="lesson of selectedLessons" :key="lesson" class="dialog__lesson">
            {{ lesson }}
          </div>
        </div>

        <div class="dialog__selects d-flex mt-1">
          <BaseSelect
            v-for="(select, name) in formSelects"
            :key="name"
            :class="`dialog__select dialog__select--${name} skills-select skills-select--${name} mt-5`"
            :disabled="name === 'level' && !select.items.length"
            :value="select.value"
            :items="select.items"
            :label="select.label"
            outlined
            filled
            solo
            single-line
            :placeholder="select.placeholder"
            @change="updateSkills($event, name)"
          /></div
      ></template>
    </template>
    <template #actions="{ closeDialog }">
      <BaseButton
        :disabled="disabled || loading"
        class="confirm-button submit"
        @click="requestLesson(closeDialog)"
        >{{ buttonText }}</BaseButton
      >
    </template>
  </SharedDialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import { sync } from "vuex-pathify"
import { converDateToSlashFormat } from "@/utils/functions"
import notyMixin from "@/mixins/notyMixin"
import spinnerMixin from "@/mixins/spinnerMixin"

export default {
  name: "ScheduleConfirmLessonDialog",

  mixins: [notyMixin, spinnerMixin],

  data: () => ({
    langAndLevel: {
      language_id: 0,
      level_id: 0,
    },
  }),

  computed: {
    ...mapGetters("layout", {
      dialogData: "getDialogConfig",
    }),
    ...mapGetters({
      userProfile: "profile/getProfile",
    }),
    selected: sync("lessons/selected"),
    languages: sync("teacher/languages"),
    levels: sync("teacher/levels"),
    teacherData: sync("teacher/teacherData"),
    title() {
      return this.$t("schedule.confirmLesson")
    },
    subtitle() {
      return this.$t("schedule.confirmLessonSubtitle")
    },
    buttonText() {
      return this.$t("schedule.confirmButton")
    },
    selectedLessons() {
      return Object.values(this.selected).map((lesson) => {
        const lessonDay = converDateToSlashFormat(lesson.day, "DD/MM/YYYY")
        return this.$t("schedule.confirmDateText", {
          day: lessonDay,
          time: lesson.time,
        })
      })
    },
    formSelects() {
      const { taught_languages } = this.teacherData ?? {}
      const languages =
        this.languages
          ?.filter((language) => taught_languages?.some((lang) => lang.id === language.value)) ///lang taught by teacher
          .map((lang) => {
            return {
              value: lang.value,
              text: lang.locales[this.locale]?.nominative || "",
            }
          })
          .sort((a, b) => a.text.localeCompare(b.text)) ?? []

      const langMaxLevel = taught_languages?.find(
        (lang) => lang.id === this.langAndLevel.language_id,
      )?.level.id

      const levels = this.levels?.filter((level) => level.value <= langMaxLevel) ?? []

      return {
        language: {
          label: this.$t("message.learningLanguage"),
          placeholder: this.$t("message.chooseLanguage"),
          rules: "required",
          items: languages,
          value: this.langAndLevel["language_id"],
        },
        level: {
          label: this.$t("message.languageLevel"),
          placeholder: this.$t("message.chooseLanguageLevel"),
          rules: "required",
          items: levels,
          value: this.langAndLevel["level_id"],
        },
      }
    },
    disabled() {
      return !this.langAndLevel["language_id"] || !this.langAndLevel["level_id"]
    },
    teacherId() {
      return this.$route.params.id ? String(this.$route.params.id) : undefined
    },
    locale() {
      return this.$i18n.locale
    },
  },
  async created() {
    this.setLevels()
    await this.$nextTick()
    this.selected = this.dialogData.data.selected
  },
  destroyed() {
    this.setSelected({})
  },
  methods: {
    ...mapActions("lessons", ["setLessons", "setScheduledLessons"]),
    ...mapActions("teacher", ["setLevels", "setTeacherInfo"]),
    ...mapMutations("lessons", {
      setSelected: "SET_SELECTED",
    }),
    async requestLesson(fn) {
      let error = null
      try {
        this.isLoading()
        await this.setLessons(this.langAndLevel)
        this.setScheduledLessons()
        if (this.teacherId) this.setTeacherInfo(this.teacherId)
        this.simpleSuccess({ text: this.$t("message.success.setLesson") }, 2000)
      } catch (err) {
        console.error(err)
        if (err.includes("limit")) {
          this.simpleError({ text: this.$t("message.error.limitExceeded") })
        } else if (err.includes("maximum monthly income")) {
          this.simpleError({ text: this.$t("message.error.teacherLessonLimitExceeded") })
        } else if (err.includes("exceeds 'active until'")) {
          this.simpleError({ text: this.$t("message.error.accountExpired") })
        } else this.simpleError({ text: this.$t("message.error.setLesson") })
        error = err
      } finally {
        this.isLoaded()
      }
      if (!error) return fn()
    },
    updateSkills(evt, name) {
      this.langAndLevel[`${name}_id`] = evt
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/rwd_breakpoints.scss";

.dialog {
  &__subtitle {
    color: var(--v-primary-darken3);
  }
  &__lesson {
    font-weight: bold;
    color: var(--v-primary-darken3);

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  &__selects {
    gap: 1rem;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    @media (max-width: $bp-m) {
      flex-direction: column;
    }
  }
  &__select {
    width: 50%;
    max-width: calc(100% - 0.5rem);

    @media (max-width: $bp-m) {
      width: 100%;
    }

    &.v-input--is-disabled {
      opacity: 0.5;
    }
  }
  &__title {
    width: 90%;
  }
}

.submit {
  @media (max-width: $bp-m) {
    margin-top: 1rem;
  }
}

::v-deep .v-text-field__details {
  @media (max-width: $bp-m) {
    display: none;
  }
}
</style>
