<template>
  <div>
    <SharedDialog class="dialog" width="1000" hide-confirm-button>
      <template #title>
        <p class="dialog__title">{{ $t("languageOnboardingDialog.title") }}</p>
      </template>
      <template #content>
        <p class="dialog__description">{{ subtitle }}</p>
        <Loading v-if="loading" class="loading"></Loading>

        <v-stepper v-else-if="!showTeacherSection" v-model="step" vertical>
          <v-stepper-step
            :class="isPrevStepComplete(1) && 'cursor-pointer'"
            :complete="step > 1"
            step="1"
            @click="setStep(1)"
          >
            <span>{{ $t("message.languageWord") }}</span>
            <small>{{ $t("languageOnboardingDialog.selectToughtLanguageDescription") }}</small>
            <span v-if="step !== 1 && stepsCompletion[1]">
              {{
                $t("languageOnboardingDialog.selectedLanguagesDescription", {
                  taughtLanguage: getLanguageName(lesson.taughtlanguageId),
                  teachingLanguage: getLanguageName(lesson.teachingLanguageId),
                })
              }}
            </span>
          </v-stepper-step>

          <v-stepper-content step="1">
            <div class="mb-4">
              <p class="mb-2 input-label--multiselect">
                {{ $t("languageOnboardingDialog.whichLanguageDoYouWantToLearn") }}
              </p>
              <BaseSelect
                v-model="lesson.taughtlanguageId"
                class="selector__teaching"
                :items="languagesOptions"
                hide-details
                outlined
                filled
                dense
                solo
                single-line
                :placeholder="$t('message.chooseLanguage')"
                :disabled="true"
              />
            </div>
            <div class="mb-4">
              <p class="mb-2 input-label--multiselect">
                {{ $t("languageOnboardingDialog.whichLanguageDoYouWantToBeTeached") }}
              </p>
              <BaseSelect
                v-model="lesson.teachingLanguageId"
                class="selector__teaching"
                :items="languagesOptions"
                hide-details
                outlined
                filled
                dense
                solo
                single-line
                :placeholder="$t('message.chooseLanguage')"
              />
            </div>
          </v-stepper-content>

          <v-stepper-step
            :class="isPrevStepComplete(2) && 'cursor-pointer'"
            :complete="step > 2"
            step="2"
            @click="setStep(2)"
          >
            <span>{{ $t("languageOnboardingDialog.teachingLevel") }}</span>
            <small>{{ $t("languageOnboardingDialog.selectTeachingLevelDescription") }}</small>
            <span v-if="step !== 2 && stepsCompletion[2]">
              {{ getLanguageLevelName(lesson.languageLevelId) }}</span
            >
          </v-stepper-step>

          <v-stepper-content step="2">
            <div class="mb-4">
              <p
                class="mb-2 input-label--multiselect"
                v-html="$t('languageOnboardingDialog.whichLevelDoYouWantToLearn')"
              />
              <BaseSelect
                v-model="lesson.languageLevelId"
                class="selector__teaching"
                :items="levelsOptions"
                item-text="text"
                item-value="value"
                hide-details
                outlined
                filled
                dense
                solo
                single-line
                :placeholder="$t('languageOnboardingDialog.selectTeachingLevel')"
              /></div
          ></v-stepper-content>

          <!--hide specialization-->
          <!--          <v-stepper-step-->
          <!--            :class="isPrevStepComplete(3) && 'cursor-pointer'"-->
          <!--            :complete="step > 3"-->
          <!--            step="3"-->
          <!--            @click="setStep(3)"-->
          <!--          >-->
          <!--            <span>{{ $t("languageOnboardingDialog.specialization") }}</span>-->
          <!--            <small>{{-->
          <!--              $t("languageOnboardingDialog.selectLanguageSpecializationDescription")-->
          <!--            }}</small>-->
          <!--            <span v-if="step !== 3 && stepsCompletion[3]">{{-->
          <!--              getSpecializationName(lesson.languageSpecializationId)-->
          <!--            }}</span>-->
          <!--          </v-stepper-step>-->

          <!--          <v-stepper-content step="3" @click="step = 3">-->
          <!--            <div class="mb-4">-->
          <!--              <p class="mb-2 input-label&#45;&#45;multiselect">-->
          <!--                {{ $t("languageOnboardingDialog.whichSpecializationDoYouWantToLearn") }}-->
          <!--              </p>-->
          <!--              <BaseSelect-->
          <!--                v-model="lesson.languageSpecializationId"-->
          <!--                class="selector__teaching"-->
          <!--                :items="specializationsOptions"-->
          <!--                hide-details-->
          <!--                item-text="text"-->
          <!--                item-value="value"-->
          <!--                outlined-->
          <!--                filled-->
          <!--                dense-->
          <!--                solo-->
          <!--                single-line-->
          <!--                :placeholder="$t('languageOnboardingDialog.selectSpecialization')"-->
          <!--              />-->
          <!--            </div>-->
          <!--          </v-stepper-content>-->

          <v-stepper-step
            :class="isPrevStepComplete(3) && 'cursor-pointer'"
            :complete="step > 4"
            step="4"
            @click="setStep(4)"
          >
            <span>{{ $t("languageOnboardingDialog.dateAndTime") }}</span>
            <small>{{ $t("languageOnboardingDialog.selectDateAndTimeDescription") }}</small>
            <span v-if="step !== 4 && stepsCompletion[4]"
              >{{ lesson.day }} - {{ getFormattedTime(lesson.time) }}</span
            >
          </v-stepper-step>

          <v-stepper-content step="4" class="date-time-section">
            <div
              class="d-flex align-center justify-center flex-wrap ga-4"
              :style="{ opacity: showDateTimeSection ? 1 : 0 }"
            >
              <v-menu :close-on-content-click="true" transition="scroll-y-transition" offset-y>
                <template #activator="{ on }">
                  <v-text-field
                    :value="formatDate"
                    :label="$t('languageOnboardingDialog.selectDate')"
                    :placeholder="$t('languageOnboardingDialog.selectDate')"
                    readonly
                    single-line
                    rounded
                    append-icon="$ArrowDown"
                    hide-details
                    class="date-picker"
                    style="max-width: 400px"
                    @click:append="on.click"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="lesson.day"
                  :locale="locale"
                  width="265px"
                  :min="new Date().toISOString().substr(0, 10)"
                  :max="maxCalendarDate"
                  no-title
                  color="#576596"
                  class="callendar"
                ></v-date-picker>
              </v-menu>
              <BaseTimePicker
                :value="lesson.time"
                :time-from="availableStartTime"
                class="ml-3"
                @input="lesson.time = $event"
              ></BaseTimePicker>
            </div>
          </v-stepper-content>

          <v-stepper-step
            :class="isPrevStepComplete(5) && 'cursor-pointer'"
            :complete="step > 5"
            step="5"
            @click="setStep(5)"
          >
            <span>{{ $t("languageOnboardingDialog.infoAboutYou") }}</span>
            <small>{{ $t("languageOnboardingDialog.infoAboutYouDescription") }}</small>
            <span v-if="step !== 5 && stepsCompletion[5]"
              >{{ lesson.infoAboutYou && lesson.infoAboutYou.substr(0, 20) }}...</span
            >
          </v-stepper-step>
          <v-stepper-content step="5">
            <v-textarea
              v-model="lesson.infoAboutYou"
              class="skills__descr w-100"
              :placeholder="$t('languageOnboardingDialog.describeYourTargetsAndExpectations')"
              auto-grow
              outlined
              hide-details
              filled
              single-line
              rows="1"
              row-height="25"
            />
          </v-stepper-content>

          <v-stepper-step
            :class="isPrevStepComplete(6) && isPrevStepComplete(5) && 'cursor-pointer'"
            :complete="hasVoiceSample"
            step="6"
            @click="setStep(6)"
          >
            <span>{{ $t("languageOnboardingDialog.voiceSample") }}</span>
            <small>{{ $t("languageOnboardingDialog.recordVoiceSampleDescription") }}</small>
            <span v-if="step !== 6 && stepsCompletion[6]">{{
              $t("languageOnboardingDialog.recorded")
            }}</span>
          </v-stepper-step>
          <v-stepper-content step="6">
            <StudentVoiceSample />
          </v-stepper-content>
        </v-stepper>
        <div v-else class="w-100 d-flex justify-center">
          <div v-if="selectedTeacher" class="card mb-3 w-100">
            <div class="d-flex flex-row align-center mb-4">
              <img :src="getTeacherImageUrl(selectedTeacher)" class="profile_photo" />
              <div class="d-flex flex-column ml-6">
                <p class="text teacher_name mb-2">
                  {{ getFullName(selectedTeacher.first_name, selectedTeacher.last_name) }}
                </p>
                <div class="d-flex flex-row align-center">
                  <v-icon size="19px">$StarFilled</v-icon>
                  <p class="text-large ml-3">{{ selectedTeacher.average_rating }}</p>
                </div>
              </div>
            </div>
            <div v-if="lessonHasBeenScheduled" class="check-message">
              <v-icon size="19px" class="check-message__icon">mdi-check-bold</v-icon>
              <span>{{ $t("languageOnboardingDialog.lessonHasBeenScheduled") }}</span>
            </div>
            <div v-else-if="showTeacherSchedule" class="d-flex align-center justify-center w-100">
              <WorkSchedule
                :init-week="week"
                :teacher-id="String(selectedTeacher.id)"
                style="margin: 0 0 0 0; padding: 0"
                custom-schedule
              />
            </div>
            <div v-else-if="exactSlot" class="check-message">
              <v-icon size="19px" class="check-message__icon">mdi-message-question-outline</v-icon>
              <span
                >{{
                  $t("message.language", { language: getLanguageName(lesson.taughtlanguageId) })
                }}
                - {{ lesson.day }} {{ getFormattedTime(lesson.time) }}</span
              >
            </div>
          </div>
          <div v-else class="alert-message">
            <v-icon size="19px" class="alert-message__icon">mdi-alert-circle</v-icon>
            <span>{{ $t(`languageOnboardingDialog.${alertMsgContent}`) }}</span>
          </div>
        </div>
        <div class="d-flex justify-space-between mt-4">
          <div>
            <BaseButton
              v-if="showTeacherSection && !hideReturnBtn && !lessonHasBeenScheduled && !loading"
              outlined
              @click="goBackToOnboardingProcess"
              >{{ $t("languageOnboardingDialog.changeSearchCriteria") }}</BaseButton
            >
          </div>
          <BaseButton v-if="allStepsCompleted && !showTeacherSection" @click="findTeacher">{{
            $t("languageOnboardingDialog.findTeacher")
          }}</BaseButton>
          <BaseButton
            v-else-if="showTeacherSchedule && !lessonHasBeenScheduled && !loading"
            :disabled="!Object.keys(selectedSlots).length"
            @click="scheduleLessonWithSelectedSlots"
            >{{ $t("languageOnboardingDialog.scheduleLesson") }}</BaseButton
          >
          <BaseButton
            v-else-if="!showTeacherSection"
            :disabled="!stepsCompletion[step]"
            @click="setNextStep"
            >{{ $t("message.next") }}</BaseButton
          >
          <BaseButton
            v-else-if="exactSlot && !lessonHasBeenScheduled"
            :disabled="loading"
            @click="confirmLessonSchedule"
            >{{ $t("languageOnboardingDialog.scheduleLesson") }}</BaseButton
          >
        </div>
      </template>
    </SharedDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import notyMixin from "@/mixins/notyMixin"
import spinnerMixin from "@/mixins/spinnerMixin"
import weekSwitcherMixin from "@/mixins/weekSwitcherMixin"
import tableMixin from "@/mixins/tableMixin"
import translateLanguage from "@/mixins/translateLanguage"
import getFullName from "@/mixins/getFullName"
import translateArrayOfLanguages from "@/mixins/translateArrayOfLanguages"
import { converDateToSlashFormat, removeFalsy } from "@/utils/functions"
import { format, parse } from "fecha"
import { api, instanceAuth } from "@/api"
import WorkSchedule from "@/components/WorkSchedule/WorkSchedule.vue"
import StudentVoiceSample from "@/components/Profile/Student/StudentVoiceSample.vue"
import pick from "lodash.pick"
import _isEqual from "lodash.isequal"

const getWeekDay = (date) => format(new Date(date), "YYYY-MM-DD")

export default {
  components: { WorkSchedule, StudentVoiceSample },
  mixins: [
    notyMixin,
    spinnerMixin,
    translateArrayOfLanguages, // getTranslatedLanguages
    translateLanguage,
    weekSwitcherMixin,
    tableMixin,
    getFullName,
  ],
  data() {
    return {
      showTeacherSection: false,
      showTeacherSchedule: false,
      showDateTimeSection: false,
      lessonHasBeenScheduled: false,
      selectedTeacher: null,
      exactSlot: null,
      lesson: {
        taughtlanguageId: null,
        teachingLanguageId: null,
        languageLevelId: null,
        languageSpecializationId: null,
        day: null,
        time: { HH: "18", mm: "00" },
        infoAboutYou: null,
      },
      step: 1,
      gracePeriod: 0,
      teacherNotFoundHistory: [],
      alertMsgContent: "weDidNotFoundTeacher",
      maxCalendarDate: null,
      hideReturnBtn: false,
    }
  },
  computed: {
    ...mapGetters({
      dialogConfig: "layout/getDialogConfig",
      userProfile: "profile/getProfile",
      userActiveUntil: "profile/getUserActiveUntil",
    }),
    ...mapState({
      specializationsOptions: (state) => state.teacher.specializations,
      levelsOptions: (state) => state.teacher.levels,
      selectedSlots: (state) => state.lessons.selected,
    }),
    languagesOptions() {
      const languages = this.$store.state?.teacher.languages
      return this.getTranslatedLanguages(languages).sort((a, b) => a.text.localeCompare(b.text))
    },
    formatDate() {
      return this.lesson.day ? converDateToSlashFormat(this.lesson.day) : ""
    },
    availableStartTime() {
      if (this.lesson.day !== format(new Date(), "YYYY-MM-DD")) return { HH: "6", mm: "00" }
      ///
      let startHour = Number(format(new Date(), "HH")) + this.gracePeriod
      return {
        HH: startHour < 7 ? 6 : startHour - 1,
        mm: startHour < 7 ? 0 : Number(format(new Date(), "mm")),
      }
    },
    locale() {
      return this.$i18n.locale
    },
    hasVoiceSample() {
      return !!this.userProfile.voice_sample_url?.length
    },
    stepsCompletion() {
      return {
        1: Boolean(this.lesson.taughtlanguageId && this.lesson.teachingLanguageId),
        2: Boolean(this.lesson.languageLevelId),
        3: true, //optional
        4: Boolean(this.lesson.day && this.lesson.time),
        5: Boolean(this.lesson.infoAboutYou),
        6: this.hasVoiceSample,
      }
    },
    allStepsCompleted() {
      return Object.values(this.stepsCompletion).every((step) => step)
    },
    subtitle() {
      if (this.showTeacherSection && this.selectedTeacher) {
        if (this.lessonHasBeenScheduled) return ""
        else if (this.showTeacherSchedule)
          return this.$t("languageOnboardingDialog.descriptionChooseLesson")
        else if (this.exactSlot) return this.$t("languageOnboardingDialog.descriptionLessonFound")
      }
      return this.$t("languageOnboardingDialog.description")
    },
  },
  async created() {
    this.isLoading()
    const { languageId } = this.dialogConfig?.data
    this.lesson.taughtlanguageId = languageId
    await this.setLevels()
    await this.setSpecializations()

    //set default teaching language
    const polishLanguage = this.languagesOptions.find((item) =>
      ["Polski", "Polish"].includes(item.text),
    )
    if (polishLanguage) this.lesson.teachingLanguageId = polishLanguage.value

    const { data } = await instanceAuth.get("/settings/show")

    this.gracePeriod = data.lesson_earliness_condition || 0
    let startHour = Number(format(new Date(), "HH")) + this.gracePeriod
    this.lesson.time = this.getInitTime(startHour)
    if (this.userProfile.about) this.lesson.infoAboutYou = this.userProfile.about

    this.maxCalendarDate = this.getEndOfNextWeek().toISOString().substr(0, 10)
    this.isLoaded()
  },
  mounted() {
    // hide specialization - temporary change in numbering, has to be called after stepper is fully loaded
    const stepInterval = setInterval(() => {
      let stepNumbers = document.getElementsByClassName("v-stepper__step__step")
      if (stepNumbers.length) {
        for (let i = 0; i < stepNumbers.length; i++) {
          if (!isNaN(Number(stepNumbers[i]?.textContent))) {
            //replace numbers, skip checks
            stepNumbers[i].textContent = String(i + 1)
          }
        }
        clearInterval(stepInterval)
      }
    }, 100)
  },
  destroyed() {
    if (this.lessonHasBeenScheduled) {
      this.$router.push({ name: "my-lessons" })
      this.setProfileData() //update profile to get current lang
    }
  },
  methods: {
    ...mapActions({
      setLevels: "teacher/setLevels",
      setSpecializations: "teacher/setSpecializations",
      setScheduledLessons: "lessons/setScheduledLessons",
      setProfileData: "profile/setProfileData",
    }),
    ...mapActions("profile", ["updateProfile"]),
    /* ---------------------------- FORMATTERS --------------------------- */
    getLanguageName(id) {
      return this.translateLanguageById({ id })
    },
    getSpecializationName(id) {
      if (!id) return
      return this.specializationsOptions.find((item) => item.value === id)?.text
    },
    getLanguageLevelName(id) {
      return this.levelsOptions.find((item) => item.value === id)?.text
    },
    getFormattedTime(time) {
      return `${time.HH}:${time.mm}`
    },
    // steps logic
    isPrevStepComplete(step) {
      const prevStep = step - 1 === 0 ? 1 : step - 1
      return this.stepsCompletion[prevStep]
    },
    setStep(step) {
      if (!this.isPrevStepComplete(step)) return
      if (step === 4 && !this.isPrevStepComplete(3)) return //step before is optional, check second step
      if (step === 6 && !this.stepsCompletion[4]) return

      this.step = step
      if (step === 4) {
        setTimeout(() => {
          this.lesson.day = this.lesson.day || format(new Date(), "YYYY-MM-DD")
          if (step === 4) {
            this.showDateTimeSection = true
          }
        }, 500)
      } else {
        this.showDateTimeSection = false
        setTimeout(() => {
          //to avoid getting overwritten in step 4 timeout
          this.showDateTimeSection = false
        }, 500)
      }
    },
    setNextStep() {
      if (this.step === 6) return
      if (!this.stepsCompletion[this.step]) return
      this.step += 1

      if (this.step === 4) {
        setTimeout(() => {
          this.lesson.day = this.lesson.day || format(new Date(), "YYYY-MM-DD")
          if (this.step === 4) {
            this.showDateTimeSection = true
          }
        }, 500)
      } else {
        this.showDateTimeSection = false
        setTimeout(() => {
          //to avoid getting overwritten in step 4 timeout
          this.showDateTimeSection = false
        }, 500)
      }
    },
    /* ---------------------------- SEARCHING TEACHER --------------------------- */
    async findTeacher() {
      // get teachers list based on lesson data
      this.showTeacherSection = true
      this.isLoading()

      // update about student
      const data = {
        about: this.lesson.infoAboutYou,
        onboarding_completed_at: JSON.stringify(new Date()),
      }
      await this.updateProfile(data)

      const {
        time,
        taughtlanguageId,
        languageLevelId,
        languageSpecializationId,
        day,
        teachingLanguageId,
      } = this.lesson

      let payload = removeFalsy({
        language_id: taughtlanguageId,
        teaching_language_id: teachingLanguageId,
        level_id: languageLevelId,
        specialization_id: languageSpecializationId,
      })

      await api
        .checkTeacherExistence(payload)
        .then(() => {
          this.findSlots(payload, time, day)
        })
        .catch(() => {
          this.alertMsgContent = "requestTeacherSent"
          this.sendRequestForTeacher(payload)
          this.hideReturnBtn = true
          this.isLoaded()
        })
    },
    async findSlots(payload, time, day) {
      try {
        const hourFrom = time?.HH >= 2 ? Number(time?.HH) - 2 : null
        const timeFrom = time?.HH && time?.mm ? `${hourFrom}:${time?.mm}` : null
        const hourTo = time?.HH >= 22 ? 0 : Number(time?.HH) + 2
        const timeTo = time?.HH && time?.mm ? `${hourTo}:${time?.mm}` : null

        payload = removeFalsy({
          ...payload,
          day: day,
          time: JSON.stringify({ from: timeFrom, to: timeTo }),
          page: 1,
          per_page: 1,
        })

        const { data: response } = await api.searchTeachers(payload)

        if (response.data.length && response.data[0]) {
          const teacher = response.data[0]
          this.selectedTeacher = teacher
          await this.checkTeacherSchedule(teacher.id)
        } else {
          this.alertMsgContent = "weDidNotFoundTeacher"
          await this.handleLackOfSlots(payload)
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.isLoaded()
      }
    },
    handleLackOfSlots(payload) {
      const currentSearch = pick(payload, [
        "language_id",
        "teaching_language_id",
        "level_id",
        "specialization_id",
      ])
      this.teacherNotFoundHistory.push(currentSearch)

      if (
        this.teacherNotFoundHistory.filter((item) => _isEqual(item, currentSearch)).length === 3
      ) {
        this.alertMsgContent = "requestTeacherSlotsSent"
        this.sendRequestForTeacher(currentSearch)
      }
    },
    async sendRequestForTeacher(currentSearch) {
      const { first_name, last_name, email, phone_number } = this.userProfile
      const { teaching_language_id, language_id, level_id, specialization_id } = currentSearch
      const { HH, mm } = this.lesson.time
      this.hideReturnBtn = true
      await api.reportLackOfSlots({
        student_full_name: `${first_name} ${last_name}`,
        student_email: email,
        student_phone_number: phone_number,
        teaching_language:
          this.languagesOptions.find((lang) => lang.value === teaching_language_id)?.text || "",
        taught_language:
          this.languagesOptions.find((lang) => lang.value === language_id)?.text || "",
        level: this.levelsOptions.find((lvl) => lvl.value === level_id)?.text || "",
        specialization:
          this.specializationsOptions.find((spec) => spec.value === specialization_id)?.text || "",
        date: this.lesson.day,
        hour: `${HH}:${mm}`,
      })
    },
    /* ---------------------------- LESSON SCHEDULING --------------------------- */
    async checkTeacherSchedule(teacherId) {
      // checks if teacher has exact slot as user selected
      // if not, shows teacher schedule component
      this.week = this.currentWeek(this.lesson.day)
      const activeUntil = parse(this.userActiveUntil, "YYYY-MM-DD")
      const lastDayOfWeek = this.week.at(-1)
      const dateFrom = getWeekDay(this.week[0] <= new Date() ? new Date() : this.week[0])
      const dateTo = activeUntil <= lastDayOfWeek ? activeUntil : getWeekDay(lastDayOfWeek)
      try {
        const { time, day } = this.lesson
        const { timetable } = await api.getTeacherSchedule(teacherId, dateFrom, dateTo)
        const slots = timetable.find((timetableItem) => timetableItem.date === day)?.slots
        if (slots) {
          const lessonTime = time?.HH && time?.mm ? `${time?.HH}:${time?.mm}` : null
          const exactSlot = slots.find((slot) => slot.time === lessonTime)
          if (exactSlot && exactSlot.available) {
            this.exactSlot = exactSlot
          } else {
            this.showTeacherSchedule = true
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.isLoaded()
      }
    },
    confirmLessonSchedule() {
      // is exact slot is found, function allows to confirm lesson schedule
      this.scheduleLessons([this.exactSlot.id])
    },
    async scheduleLessons(slotIds) {
      // final step of scheduling lesson
      try {
        this.isLoading()
        const { taughtlanguageId, languageLevelId } = this.lesson

        const payload = {
          language_id: taughtlanguageId,
          level_id: languageLevelId,
          teacher_id: this.selectedTeacher.id,
          time_slot_ids: slotIds,
        }

        await api.createLesson(payload)

        this.lessonHasBeenScheduled = true
      } catch (err) {
        console.error(err)
      } finally {
        this.isLoaded()
      }
    },
    async scheduleLessonWithSelectedSlots() {
      // schedules lesson with selected slots in WorkSchedule component
      try {
        this.isLoading()
        const slots = Object.keys(this.selectedSlots)
        await this.scheduleLessons(slots)
        this.simpleSuccess({ text: this.$t("message.success.setLesson") }, 2000)
      } catch (err) {
        console.error(err)
      } finally {
        this.isLoaded()
      }
    },
    /* ---------------------------- UTILS --------------------------- */
    getTeacherImageUrl(teacherData) {
      return teacherData?.image_url ?? this.resolveImgUrl("profile.svg")
    },
    goBackToOnboardingProcess() {
      this.showTeacherSection = false
      this.showTeacherSchedule = false
      this.step = 4
      this.showDateTimeSection = true
      this.selectedTeacher = null
    },
    timeStringPadding(val) {
      if (val < 10) return `0${val}`
      return String(val)
    },
    getInitTime(startHour) {
      function setTomorrowMorning() {
        let tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        this.lesson.day = format(tomorrow, "YYYY-MM-DD")
        return {
          HH: "06",
          mm: "00",
        }
      }

      let hour = startHour
      let minutes = Math.ceil((format(new Date(), "mm") / 60) * 4) * 15
      if (minutes == 60) {
        minutes = 0
        hour += 1
      }
      if (hour > 23) return setTomorrowMorning()
      return {
        HH: this.timeStringPadding(hour),
        mm: this.timeStringPadding(minutes),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/rwd_breakpoints.scss";

.dialog {
  width: 800px;
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
  }

  &__description {
    font-size: 0.875rem;
    margin: 0.5rem 0 1rem 0 !important;
  }
}

.v-stepper {
  box-shadow: none !important;
}

::v-deep .v-stepper__label {
  text-align: center !important;
}

.date-picker {
  display: flex;
  align-items: center;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px !important;
  height: 48px !important;
  background-color: #fafbff !important;
  cursor: pointer !important;
  ::v-deep .v-input__control .v-input__slot {
    cursor: pointer !important;
    .v-text-field__slot input {
      cursor: pointer !important;
    }
  }
}

.date-time-section {
  ::v-deep .v-stepper__wrapper {
    overflow: visible !important;
  }
}

.vue__time-picker .time-picker .timepicker {
  height: 48px !important;
}
::v-deep .timepicker {
  height: 48px !important;
}
::v-deep .vue__time-picker input.display-time {
  height: 48px !important;
  border-radius: 4px;
  cursor: pointer !important;
}

::v-deep .v-text-field input {
  font-weight: 600;
  font-size: 16px;
}

::v-deep .v-stepper__label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

::v-deep .dropdown-btn {
  margin-right: 16px !important;
  .v-icon {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
  }
}

::v-deep .display-time {
  padding-left: 24px !important;
}

.card {
  max-width: 100%;
  border-radius: 8px;
  overflow-wrap: anywhere;
  word-wrap: break-word;
  background-color: var(--v-grayscale-lighten3);

  @media (min-width: ($bp-t + 1)) {
    max-width: 625px;
  }

  &__description {
    hyphens: auto;
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
  }

  .profile_photo {
    width: 112px;
    min-width: 112px;
    height: 112px;
    border: 1px solid #efefef;
    border-radius: 24px;
    object-fit: cover;
  }

  .teacher_name {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
  }
}

.check-message {
  background-color: #bfdbfe;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  &__icon {
    color: var(--v-primary-base);
    margin-right: 8px;
  }
}
.alert-message {
  background-color: #fe332cb3;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  &__icon {
    color: var(--v-error-base);
    margin-right: 8px;
  }
}

.audio-player {
  max-width: 100%;
  padding: 0 16px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
