<template>
  <SharedEditDialog
    ><template #title>
      <p class="title">{{ messages.editInfoAboutYou }}</p>
    </template>
    <template #content>
      <div class="mt-6 input-container">
        <p class="mb-1 input-container__title">{{ messages.infoAboutYou }}</p>
        <textarea
          v-model="about"
          :placeholder="$t('languageOnboardingDialog.describeYourTargetsAndExpectations')"
          auto-grow
          outlined
          hide-details
          filled
          single-line
          :height="150"
          rows="1"
          row-height="25"
          class="input-container__text-area"
        />
      </div>
    </template>
    <template #actions="{ closeDialog }">
      <BaseButton
        outlined
        :clear="true"
        color="grayscale-darken3"
        class="mr-4 cancel-button"
        @click="closeDialog"
        >{{ messages.void }}</BaseButton
      >
      <BaseButton class="confirm-button" @click="closeDialog(submitEditExperience)">{{
        messages.confirmChanges
      }}</BaseButton>
    </template>
  </SharedEditDialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import notyMixin from "@/mixins/notyMixin"
import spinnerMixin from "@/mixins/spinnerMixin"

export default {
  mixins: [notyMixin, spinnerMixin],

  data: () => ({
    about: "",
  }),

  computed: {
    ...mapGetters("layout", {
      dialogData: "getDialogConfig",
    }),
    messages() {
      return {
        editInfoAboutYou: this.$t("languageOnboardingDialog.editInfoAboutYou"),
        infoAboutYou: this.$t("languageOnboardingDialog.infoAboutYou"),
        void: this.$t("message.void"),
        confirmChanges: this.$t("message.confirmChanges"),
      }
    },
  },
  created() {
    this.about = this.dialogData.data.about
  },

  methods: {
    ...mapActions("profile", {
      updateProfile: "updateProfile",
    }),

    async submitEditExperience() {
      const data = {
        about: this.about,
      }
      try {
        this.isLoading()
        await this.updateProfile(data)
        this.simpleSuccess({ text: this.$t("message.success.editDescription") })
      } catch (_) {
        this.simpleError({ text: this.$t("message.error.updateData") })
        return Promise.reject("failed to update about")
      } finally {
        this.isLoaded()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/rwd_breakpoints.scss";

.title {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--v-grayscale-darken3);
}

.cancel-button {
  width: 81px !important;
  height: 49px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 0;
  border: 1px solid var(--v-grayscale-lighten4);
  color: var(--v-grayscale-darken3) !important;
}

.input-container {
  position: relative;

  &__title {
    font-weight: 500;
    font-size: 16px;
    color: var(--v-primary-darken3);
  }

  &__text-area {
    width: 100%;
    height: 400px;
    background-color: var(--v-primary-lighten2) !important;
    border: 1px solid var(--v-grayscale-lighten4);
    border-radius: 0.5rem;
    resize: none;
    padding: 0.8rem;

    &:focus {
      outline: none;
    }

    &--error {
      color: var(--v-error-darken1);
      border: 2px solid var(--v-error-darken1);
    }
  }

  &__error-message {
    position: absolute;
    bottom: -25px;
    font-size: 0.875rem;
    color: var(--v-error-darken1);
    @media (max-width: $bp-m) {
      bottom: -40px;
    }
  }
}
</style>
