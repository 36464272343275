import { format } from "fecha"
import _isEqual from "lodash.isequal"
import _transform from "lodash.transform"
import { serialize } from "object-to-formdata"

export function delay(t, v) {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, v), t)
  })
}

export const isObjectLike = (val) => val !== null && typeof val === "object"

export const getType = (v) =>
  v === undefined ? "undefined" : v === null ? "null" : v.constructor.name.toLowerCase()

export const removeFalsy = (object) => {
  Object.entries(object).forEach(([key, value]) => {
    const isFile = getType(value) === "file"
    if (isObjectLike(value) && !isFile) {
      removeFalsy(value)
    }
    if (
      (isObjectLike(value) && !Object.keys(value).length && !isFile) ||
      value === null ||
      value === undefined ||
      value.length === 0
    ) {
      if (Array.isArray(object)) {
        object.splice(key, 1)
      } else {
        delete object[key]
      }
    }
  })
  return object
}

export const serializeFormData = (object) => serialize(object)

export const generateFormData = (obj) => {
  let data = new FormData()
  for (const key of Object.keys(obj)) {
    const type = getType(obj[key])
    let value
    if (type === "array") {
      value = obj[key]
    } else if (isObjectLike(obj[key]) && type !== "file") {
      value = JSON.stringify(obj[key])
    } else {
      value = obj[key]
    }

    if (getType(value) === "array") {
      value.forEach((val) => {
        data.append(`${key}[]`, val)
      })
    } else {
      data.append(key, value)
    }
  }
  return data
}

export const convertDateToDashFormat = (date) => {
  return format(new Date(date), "DD-MM-YYYY")
}

export const converDateToSlashFormat = (date) => {
  return format(new Date(date), "DD/MM/YYYY")
}
/**
 * Omits the key-value pairs corresponding to the given keys from an object.
 * @see https://www.30secondsofcode.org/js/s/omit
 * @param {Object} obj
 * @param {Array} arr
 * @returns
 */
export const omit = (obj, arr) =>
  Object.keys(obj)
    .filter((k) => !arr.includes(k))
    .reduce((acc, key) => ((acc[key] = obj[key]), acc), {})

/**
 * Performs left-to-right function composition.
 * @see https://www.30secondsofcode.org/js/s/pipe-functions
 * @param  {...any} fns functions to be called in a row
 * @returns result of the last function
 */
export const pipeFunctions = (...fns) =>
  fns.reduce(
    (f, g) =>
      (...args) =>
        g(f(...args)),
  )

/**
 * Compare two objects and return the difference
 * @param {Object} base entry object to compare with
 * @param {Object} object desired object to be compared with
 * @returns {Object} difference between the two objects
 */
export function diff(base, object) {
  function changes(base, object) {
    const accumulator = {}
    Object.keys(base).forEach((key) => {
      if (object[key] === undefined) {
        accumulator[`${key}`] = base[key]
      }
    })
    return _transform(
      object,
      (accumulator, value, key) => {
        if (base[key] === undefined) {
          accumulator[`${key}`] = value
        } else if (!_isEqual(value, base[key])) {
          accumulator[key] = value
        }
      },
      accumulator,
    )
  }
  return changes(base, object)
}

export function getAvailableHours(hoursFrom) {
  const availableHours = []
  let i = hoursFrom + 1 >= 6 ? hoursFrom + 1 : 6

  for (i; i < 24; i++) {
    availableHours.push(i)
  }

  return availableHours
}

export function getSpecializationTranslation(specialization, locale) {
  const specializationNameKey = {
    en: "name",
    pl: "name_pl",
  }

  return specialization[specializationNameKey[locale]]
}
