import { render, staticRenderFns } from "./LanguageOnboardingDialog.vue?vue&type=template&id=1a8d8f70&scoped=true&"
import script from "./LanguageOnboardingDialog.vue?vue&type=script&lang=js&"
export * from "./LanguageOnboardingDialog.vue?vue&type=script&lang=js&"
import style0 from "./LanguageOnboardingDialog.vue?vue&type=style&index=0&id=1a8d8f70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a8d8f70",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VDatePicker,VIcon,VMenu,VStepper,VStepperContent,VStepperStep,VTextField,VTextarea})
