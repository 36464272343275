import qs from "query-string"
import spreadResponse from "@/utils/spreadResponse"

const paths = {
  teacherInfo: "/teachers/show",
  getTeachersDummy: "/teachers/dummy",
  searchTeachers: "/teachers/search",
  getLanguages: "/languages/list",
  getSpecializations: "/specializations/list",
  getLevels: "/levels/list",
  getTeacherSchedule: "/teachers/timetables/show",
  updateTeacherSchedule: "/teachers/timetables/update",
  itTeachers: "/teachers/it_teachers",
  studentProfile: "/students/show",
  checkTeacher: "/language_onboardings/check_teacher_existence",
}

export default {
  getTeacherInfo: async (axios, teacher_id) => {
    try {
      const res = await axios.get(`${paths.teacherInfo}?teacher_id=${teacher_id}`)
      return spreadResponse(res)
    } catch (error) {
      return { status: error.response.status }
    }
  },
  searchTeachers(axios) {
    /**
     *
     * @param {Object} payload is an `Object` with the following properties: `language_id`, `level_id`, `day`, `time`, `offset`
     * @returns
     */
    return async (payload) => await axios.get(`${paths.searchTeachers}?${qs.stringify(payload)}`)
  },
  checkTeacherExistence(axios) {
    return async (payload) => await axios.get(`${paths.checkTeacher}?${qs.stringify(payload)}`)
  },
  getDymmyTeachers(axios) {
    return async () => await axios.get(paths.getTeachersDummy)
  },
  getTeacherSchedule: (axios) => async (teacher_id, date_from, date_to) => {
    const model = {
      teacher_id,
      date_from,
      date_to,
    }
    const { data } = await axios.get(`${paths.getTeacherSchedule}?${qs.stringify(model)}`)
    return data
  },
  getLanguages: async (axios) => {
    const res = await axios.get(paths.getLanguages)

    return res
  },
  getSpecializations: async (axios) => {
    const res = await axios.get(paths.getSpecializations)

    return res
  },
  getLevels: async (axios) => {
    const res = await axios.get(paths.getLevels)

    return res
  },
  updateTeacherSchedule: async (axios, payload) => {
    const res = await axios.post(paths.updateTeacherSchedule, payload)

    return res
  },
  getItTeachers: async (axios) => {
    const res = await axios.get(paths.itTeachers)
    return res
  },
  getStudentProfile: async (axios, user_id) => {
    const res = await axios.get(`${paths.studentProfile}`, { params: { user_id } })
    return res
  },
}
