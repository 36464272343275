<template>
  <div>
    <SharedDialog class="dialog">
      <template #title>
        <p class="dialog__title">{{ title }}</p>
      </template>
      <template #content>
        <p class="dialog__description" style="font-size: 16px">
          {{ $t("reportAbsenceDialog.description", { absentee: absenteeName }) }}
        </p>
      </template>
      <template #actions="{ closeDialog }">
        <BaseButton
          class="confirm-button"
          :disabled="loading"
          @click="closeDialog(reportTeacherAbsence)"
          >{{ $t("message.confirm") }}</BaseButton
        ></template
      >
    </SharedDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import notyMixin from "@/mixins/notyMixin"
import spinnerMixin from "@/mixins/spinnerMixin"
import _get from "lodash.get"
import { api } from "@/api"

export default {
  mixins: [notyMixin, spinnerMixin],
  data() {
    return {
      mode: "student",
      title: "",
    }
  },
  computed: {
    ...mapGetters("layout", {
      dialogData: "getDialogConfig",
    }),

    absenteeName() {
      return {
        student: this.$t("message.student"),
        teacher: this.$t("message.teacher"),
      }[this.mode]?.toLowerCase()
    },
  },
  created() {
    const { mode, title } = this.dialogData?.data || {}
    this.mode = mode
    this.title = title || this.$t("reportAbsenceDialog.title")
  },
  methods: {
    ...mapActions("lessons", {
      updateLesson: "updateLesson",
      setClosedLessons: "setClosedLessons",
      setScheduledLessons: "setScheduledLessons",
    }),

    async reportTeacherAbsence() {
      const { lesson, mode, table } = this.dialogData.data
      const absenteeKey = {
        teacher: "teacher.id",
        student: "student.id",
      }[mode]

      const absenteeId = _get(lesson, absenteeKey)
      try {
        this.isLoading()
        const payload = {
          absentee_id: absenteeId,
          lesson_id: lesson.id,
          note: this.$t("reportAbsenceDialog.absenteeDidNotShowUp", {
            absentee: this.absenteeName,
          }),
        }
        await api.reportAbsence(payload)
        this.simpleSuccess({ text: this.$t("reportAbsenceDialog.success") })
        if (this.$route.path.includes("my-lessons")) {
          if (table === "closedLessons") await this.setClosedLessons()
          else await this.setScheduledLessons()
        } else {
          var leaveBtn = document.getElementsByClassName("leave-class")[0]
          leaveBtn?.click()
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.isLoaded()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
  }

  &__text-area {
    width: 100%;
    height: 94px;
    background-color: #fafbff !important;
    border: 1px solid #eceff9;
    border-radius: 0.5rem;
    resize: none;
    padding: 0.4rem 0.6rem;

    &:focus {
      outline: none;
    }
  }
  &__description {
    font-size: 0.875rem;
    margin: 0.5rem 0 1rem 0 !important;
  }
}
</style>
