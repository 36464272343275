<template>
  <div>
    <div
      v-if="hasVoiceSample && !voiceSample.isRecording"
      class="d-flex align-center flex-column ga-4"
    >
      <p class="mb-2 input-label--multiselect text-center">
        {{ voiceSampleDescription }}
      </p>
      <p v-if="false" class="mb-2 input-label--multiselect text-center">
        <!-- hide until we get text to read -->
        {{ $t("languageOnboardingDialog.keepVoiceSampleOrRecordNew") }}
      </p>
      <div class="d-flex align-center justify-center ga-8 flex-wrap">
        <audio :src="userProfile.voice_sample_url" controls class="audio-player"></audio>
        <span class="input-label--multiselect text-center">{{ $t("message.or") }}</span>
        <BaseButton color="secondary" @click="prepareAndStartRecording">{{
          $t("languageOnboardingDialog.recordNewVoiceSample")
        }}</BaseButton>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center flex-wrap">
      <span v-if="!voiceSample.isRecording" class="text-center mb-2">
        {{ $t("languageOnboardingDialog.youDoNotHaveVoiceSampleDescription") }}<br />
        <i> {{ $t("languageOnboardingDialog.voiceSampleTextToRead") }}</i>
      </span>
      <div v-if="voiceSample.isRecording" class="d-flex flex-column align-center">
        <div class="mb-2 text-center">
          {{ $t("languageOnboardingDialog.readSentenceBelow") }}<br />
          <i>{{ $t("languageOnboardingDialog.voiceSampleTextToRead") }}</i>
        </div>
        <div class="d-flex flex-wrap">
          <span class="recorder__counter">{{ voiceSample.recorderCounter }}</span>
          <v-progress-circular
            v-if="voiceSample.isRecording"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <div class="recorder__dot"></div>
        </div>
      </div>
      <BaseButton v-else color="secondary" class="ml-3" @click="prepareAndStartRecording">{{
        $t("languageOnboardingDialog.record")
      }}</BaseButton>
    </div>
  </div>
</template>

<script>
import Recorder from "audio-recorder-js"
import { mapActions, mapGetters } from "vuex"
import checkMicPermissionMixin from "@/mixins/checkMicPermissionMixin"
import spinnerMixin from "@/mixins/spinnerMixin"

export default {
  mixins: [checkMicPermissionMixin, spinnerMixin],
  data() {
    return {
      voiceSample: {
        recorder: null,
        isRecording: false,
        audioBlob: null,
        recorderCounter: 10,
        counterInterval: null,
      },
      stream: null,
      showMsgAfterRecording: false,
    }
  },
  computed: {
    ...mapGetters({
      userProfile: "profile/getProfile",
    }),
    hasVoiceSample() {
      return !!this.userProfile.voice_sample_url?.length
    },
    voiceSampleDescription() {
      if (this.showMsgAfterRecording)
        return this.$t("languageOnboardingDialog.thankYouForRecording")
      return this.$t("languageOnboardingDialog.recordVoiceSampleContentDescription")
    },
  },
  methods: {
    ...mapActions("profile", ["setProfileData", "updateProfile"]),
    /* ---------------------------- RECORDING VOICE SAMPLE --------------------------- */
    async prepareAndStartRecording() {
      await this.initRecording()
      this.start()
    },
    async initRecording() {
      try {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)()

        this.voiceSample.recorder = new Recorder(audioContext, {
          // An array of 255 Numbers
          // You can use this to visualize the audio stream
          // onAnalysed: (data) => console.log(data),
          numChannels: 2,
          sampleRate: 44100,
          sampleBits: 16, // 8 or 16
          bufferLen: 4096,
          mimeType: "audio/wav",
        })

        // Requesting AUDIO permission only:
        await this.checkMicPermission()

        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true })

        this.voiceSample.recorder.init(this.stream)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async done() {
      await this.stop()

      try {
        this.isLoading()
        const fileName = "voice-confirmation.wav"
        const file = new File([this.voiceSample.audioBlob], fileName, {
          type: this.voiceSample.audioBlob.type,
        })

        const payload = { voiceSample: file }

        await this.updateProfile(payload)
        await this.setProfileData()
        this.showMsgAfterRecording = true
      } catch (err) {
        console.error(err)
        this.simpleError({ text: this.$t("message.error.updateData") })
        return Promise.reject("failed to update profile data")
      } finally {
        this.isLoaded()
      }
    },
    async start() {
      await this.voiceSample.recorder.start()
      this.voiceSample.recorderCounter = 10
      this.voiceSample.isRecording = true
      setTimeout(() => {
        clearInterval(this.counterInterval)
        this.done()
      }, 10000)

      this.counterInterval = setInterval(() => {
        this.voiceSample.recorderCounter =
          this.voiceSample.recorderCounter - 1 >= 0 ? this.voiceSample.recorderCounter - 1 : 0
      }, 1000)
    },
    async stop() {
      // returns {blob, buffer}
      // buffer is an AudioBuffer
      const { blob } = await this.voiceSample.recorder.stop()
      this.voiceSample.isRecording = false

      this.voiceSample.audioBlob = blob

      const tracks = this.stream.getTracks()
      tracks.forEach((track) => {
        track.stop()
      })

      return Promise.resolve()
    },
  },
}
</script>

<style lang="scss" scoped>
.recorder {
  &__counter {
    position: absolute;
    margin: 0 auto;
    transform: translate(8px, 5px);
    width: 16px !important;
    text-align: center;
  }

  &__dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    transform: translate(1px, 0px);
    animation: blink 1s linear infinite;
  }
}

@keyframes blink {
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
}

.audio-player {
  max-width: 100%;
  padding: 0 16px;
}
</style>
