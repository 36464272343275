import axios from "axios"
import user from "./user"
import lessons from "./lessons"
import profile from "./profile"
import teachers from "./teachers"
import vonage from "./vonage"

import errorHandler from "@/utils/errorHandler"

const url = process.env.VUE_APP_API_URL ?? "http://localhost:3000"
const prefix = process.env.VUE_APP_API_PREFIX ?? "/api"
const baseURL = url + prefix

const auth = JSON.parse(localStorage.getItem("auth")) ?? null

const headers = {
  "Access-Control-Allow-Origin": "*",
}

const headerWithToken = {
  ...headers,
  Authorization: auth?.token ?? "",
}

const instance = axios.create({
  baseURL,
  headers,
})

export const instanceAuth = axios.create({
  baseURL,
  headers: headerWithToken,
})

export const api = {
  /* ---------------------------------- AUTH ---------------------------------- */
  checkInvitation: async (token) => {
    try {
      return await user.acceptInvitation(instance, token)
    } catch (error) {
      return Promise.reject(errorHandler(error))
    }
  },
  login: async (credentials) => {
    try {
      return await user.logIn(instance, credentials)
    } catch (error) {
      return Promise.reject(errorHandler(error))
    }
  },
  logout: () => {
    return user.logOut(instanceAuth)
  },
  resetRequest: async (email) => {
    try {
      return await user.resetPassword(instance, email)
    } catch (error) {
      errorHandler(error)
      return Promise.reject(errorHandler(error))
    }
  },
  setNewPassword: async (newPassObj) => {
    try {
      return await user.updatePassword(instance, newPassObj)
    } catch (error) {
      return Promise.reject(errorHandler(error))
    }
  },
  validateResetToken: async (token) => {
    try {
      return await user.validateResetToken(instance, token)
    } catch (error) {
      return Promise.reject(errorHandler(error))
    }
  },
  validateEmailAvailability: async (email) => {
    try {
      return await user.validateEmailAvailability(instance, email)
    } catch (error) {
      return Promise.reject(errorHandler(error))
    }
  },
  /* ---------------------------------- PROFILE ---------------------------------- */
  getProfileData: async () => await profile.getProfileData(instanceAuth).catch(errorHandler),
  registerTeacher: async (profileData) =>
    await profile.registerTeacher(instance, profileData).catch(errorHandler),
  updateProfile: async (profileData) => {
    try {
      return await profile.updateProfile(instanceAuth, profileData)
    } catch (error) {
      errorHandler(error)
      return Promise.reject("failed to update profile")
    }
  },
  fetchAgreements: async () => await profile.fetchAgreements(instanceAuth).catch(errorHandler),
  /* --------------------------------- LESSONS -------------------------------- */
  getScheduledLessons: async () => lessons.getScheduledLessons(instanceAuth),
  getGroupLessons: async () => lessons.getGroupLessons(instanceAuth),
  createLessonInteraction: async (lessonData) => {
    return await lessons.createLessonInteraction(instanceAuth, lessonData)
  },
  getDummyScheduledLessons: async () =>
    await lessons.getDummyScheduledLessons(instanceAuth).catch(errorHandler),
  getClosedLessons: async () => lessons.getClosedLessons(instanceAuth),
  createLesson: async (lessonData) => {
    try {
      return await lessons.createLesson(instanceAuth, lessonData)
    } catch (error) {
      errorHandler(error)
      return Promise.reject(error.response.data.error[0])
    }
  },
  cancelLesson: async (credentials) => {
    try {
      await lessons.cancelLesson(instanceAuth, credentials)
    } catch (error) {
      errorHandler(error)
      return Promise.reject("failed to cancel lesson")
    }
  },
  updateLesson: async (updateData) => {
    return await lessons.updateLesson(instanceAuth, updateData).catch(errorHandler)
  },
  removeFile: async (updateData) => {
    return await lessons.removeLessonFile(instanceAuth, updateData).catch(errorHandler)
  },
  rateLesson: async (credentials) =>
    await lessons.rateLesson(instanceAuth, credentials).catch(errorHandler),
  generateToken: async (payload) => {
    try {
      return await lessons.generateToken(instanceAuth)(payload)
    } catch (error) {
      errorHandler(error)
      return Promise.reject("failed to load token")
    }
  },
  getLesson: async (payload) => {
    try {
      return await lessons.getLesson(instanceAuth)(payload)
    } catch (error) {
      errorHandler(error)
      return Promise.reject("failed to load lesson")
    }
  },
  reportAbsence: async (payload) => {
    try {
      return await lessons.reportAbsence(instanceAuth)(payload)
    } catch (error) {
      errorHandler(error)
      return Promise.reject("failed to report absence")
    }
  },
  /* -------------------------------- TEACHERS -------------------------------- */
  getTeacherInfo: async (teacher_id) =>
    await teachers.getTeacherInfo(instanceAuth, teacher_id).catch(errorHandler),
  searchTeachers: async (teacher_id) => {
    try {
      return await teachers.searchTeachers(instanceAuth)(teacher_id)
    } catch (error) {
      errorHandler(error)
      return Promise.reject("failed to search teachers")
    }
  },
  getDummyTeachers: async () => {
    try {
      return await teachers.getDymmyTeachers(instanceAuth)()
    } catch (error) {
      errorHandler(error)
      return Promise.reject("failed to load dummy teachers data")
    }
  },
  getTeacherSchedule: async (teacher_id, date_from, date_to) => {
    return await teachers
      .getTeacherSchedule(instanceAuth)(teacher_id, date_from, date_to)
      .catch(errorHandler)
  },
  getLanguages: async () => await teachers.getLanguages(instanceAuth).catch(errorHandler),
  getSpecializations: async () =>
    await teachers.getSpecializations(instanceAuth).catch(errorHandler),
  getLevels: async () => await teachers.getLevels(instanceAuth).catch(errorHandler),
  updateTeacherSchedule: async (schedule) =>
    await teachers.updateTeacherSchedule(instanceAuth, schedule),
  getItTeachers: async () => await teachers.getItTeachers(instanceAuth).catch(errorHandler),
  getStudentProfile: async (student_id) =>
    await teachers.getStudentProfile(instanceAuth, student_id),
  /* --------------------------------- VONAGE --------------------------------- */
  startArchiveRecording: async (lessonId) => {
    try {
      return await vonage.startArchiveRecording(instanceAuth)(lessonId)
    } catch (error) {
      errorHandler(error)
      return Promise.reject("failed to start recording")
    }
  },
  selectArchiveStream: async (lessonId, streamId) => {
    try {
      return await vonage.selectArchiveStream(instanceAuth)(lessonId, streamId)
    } catch (error) {
      errorHandler(error)
      return Promise.reject("failed to select stream")
    }
  },
  getArchive: async (archiveId) => {
    try {
      return await vonage.getArchive(instanceAuth)(archiveId)
    } catch (error) {
      errorHandler(error)
      return Promise.reject("failed to get archive")
    }
  },
  reportLackOfSlots: (payload) => {
    try {
      return instanceAuth.post("/language_onboardings/report_lack_of_slots", payload)
    } catch (error) {
      errorHandler(error)
      return Promise.reject("failed to report lack of slots")
    }
  },
  checkTeacherExistence: async (params) => {
    try {
      return await teachers.checkTeacherExistence(instanceAuth)(params)
    } catch (error) {
      errorHandler(error)
      return Promise.reject("no teacher found")
    }
  },
}
