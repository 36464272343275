import notyMixin from "@/mixins/notyMixin"
export default {
  mixins: [notyMixin],
  methods: {
    async checkMicPermission(video = false) {
      try {
        // Requesting AUDIO permission only:
        await navigator.mediaDevices.getUserMedia({ audio: true, video })
      } catch (err) {
        console.error(err)

        return Promise.reject("UserPermissionDenied")
      }
    },
  },
}
